import { Flex, Icon, Text, Textarea, useColorModeValue } from '@chakra-ui/react';
import Card from 'components/card/Card';
import { RecordIcon, CloseIcon, StopIcon } from 'components/icons/Icons';
import { useState, useRef } from 'react';
import { BackButton, NextButton } from 'views/common/common';

function VoiceRecorder(props: {
  header: string,
  buttonText: string,
  notesPlaceholderText?: string,
  dealId?: string, // move to parent and have a callback to process the audio here instead
}) {
  const { header, buttonText, notesPlaceholderText, dealId } = props;
  const [isRecording, setIsRecording] = useState(false);
  const [notesText, setNotesText] = useState<string | undefined>();
  const [audioFiles, setAudioFiles] = useState<{ blob: Blob, url: string }[]>([]);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const audioChunksRef = useRef<Blob[]>([]);


  const processVoiceMemoUrl = 'https://inwise-node-functions2.azurewebsites.net/api/transcribe-voice-note?code=nVdFLOMagdhFcOyqPPGQ1klX-eK5H0jTqbydqhr6hCVPAzFu8rCxww%3D%3D';

  const startRecording = async () => {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    mediaRecorderRef.current = new MediaRecorder(stream);

    mediaRecorderRef.current.ondataavailable = (event) => {
      audioChunksRef.current.push(event.data);
    };

    mediaRecorderRef.current.onstop = () => {
      const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/wav' });
      const audioUrl = URL.createObjectURL(audioBlob);

      setAudioFiles((prev) => [
        ...prev,
        { blob: audioBlob, url: audioUrl }
      ]);

      audioChunksRef.current = [];
    };

    mediaRecorderRef.current.start();
    setIsRecording(true);
  };


  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
    }
    if (mediaRecorderRef.current.stream) {
      mediaRecorderRef.current.stream.getTracks().forEach(track => track.stop());
    }
  };

  const handleCancel = () => {
    // Add cancel logic here
    if (mediaRecorderRef?.current?.stream) {
      mediaRecorderRef.current.stream.getTracks().forEach(track => track.stop());
    }
  };

  const handleSave = async () => {
    console.log(notesText);

    // Array to hold base64 audio data
    const base64AudioFiles: string[] = [];

    // Convert audio blobs to base64 strings
    for (const file of audioFiles) {
      const reader = new FileReader();
      reader.onloadend = async () => {
        const base64Audio = reader.result?.toString().split(',')[1]; // Get base64 string part

        if (base64Audio) {
          base64AudioFiles.push(base64Audio);
        }

        // Once all files are converted, prepare the payload and send it
        if (base64AudioFiles.length === audioFiles.length) {
          const payload = {
            dealId: dealId,
            notes: notesText || '',
            timestamp: new Date().toISOString(),
            user: localStorage.getItem('loggedUserName') || '',
            audio: base64AudioFiles, // Array of base64-encoded audio files
          };

          try {
            const response = await fetch(processVoiceMemoUrl, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(payload),
            });

            if (response.ok) {
              const data = await response.json();
              console.log('Successfully uploaded data:', data);
            } else {
              console.error('Error uploading data:', response.statusText);
            }
          } catch (error) {
            console.error('Error during fetch:', error);
          }
        }
      };

      reader.readAsDataURL(file.blob); // Convert the blob to base64
    }
  };

  function removeTrack(index: number): void {
    console.log('removing track', index);
    setAudioFiles((prev) => prev.filter((_, i) => i !== index));
  }

  const titleColor = useColorModeValue('navy.700', 'white');
  const textColorLight = useColorModeValue('#c7cee2', 'white');
  const textColorTertiary = useColorModeValue('#A3AED0', 'white');

  //add loading state
  //add recording animation
  return (
    <Card mt='20px' p='20px'>
      <Flex flexDir={'column'} flexGrow={1} flexBasis={0}>
        <Flex align='center' textAlign='center' pb='10px'>
          <Text fontWeight={'bold'} color={titleColor}>{header}</Text>
        </Flex>
        <Flex alignItems={'center'} mb='20px' mt='10px'>
          <Flex
            as="button"
            onClick={isRecording ? stopRecording : startRecording}
            color={textColorLight}
            _hover={{ color: textColorTertiary, bg: 'brand.800' }}
            bg={'brand.900'} borderRadius={'50%'} p={'8px'} boxShadow={'0 4px 8px 0 rgba(0,0,0,0.3)'} w={'40px'} h={'40px'}
          >
            <Icon as={isRecording ? StopIcon : RecordIcon} w='25px' h='25px' color={'white'} />
          </Flex>
          <Text ml='10px' color={textColorTertiary}>{buttonText}</Text>
        </Flex>
        {audioFiles?.length > 0 && (
          <Flex flexDir="column" w="100%">
            {audioFiles?.map((url, index) => (
              <Flex key={index} alignItems="center" mb="10px">
                <audio src={url.url} controls style={{ width: '100%', height: '40px', border: 'none' }} />
                <Flex
                  as="button"
                  onClick={() => removeTrack(index)}
                  color={textColorTertiary}

                  borderRadius={'50%'} w={'20px'} h={'20px'} ml="10px"
                >
                  <Icon as={CloseIcon} w='20px' h='20px' color={textColorTertiary} _hover={{ color: 'brand.800' }} />
                </Flex>
              </Flex>
            ))}
          </Flex>
        )}
        <Text color={titleColor} fontSize='md' mt='20px'>Notes:</Text>
        <Textarea
          value={notesText}
          onChange={(e) => setNotesText(e.target.value)}
          variant='outline'
          placeholder={notesPlaceholderText}
          mt='10px'
          p={2}
          fontSize='md'
        />
        {audioFiles?.length > 0 && <Flex justifyContent="flex-end" mt={4} w={'100%'}>
          <BackButton mr={2} onClick={handleCancel} buttonText={'Cancel'} w={'100px'} />
          <NextButton onClick={handleSave} buttonText={'Process'} w={'100px'} isDisabled={null} />
        </Flex>}
      </Flex>
    </Card>
  );
};

export default VoiceRecorder;
